import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Button } from 'semantic-ui-react';
import { useResponsive } from 'portal-components';

import messages from '../../messages';
import contentData from '../../content/pages/full-fibre.json';

import './style.css';

export default function FullFibrePage(props) {
  const { config } = props;

  const { slides } = contentData;
  const { formatMessage } = useIntl();

  // Get window size
  const { width } = useResponsive();

  return (
    <div className="fullfibre top-page-spacing">
      {slides.map((slide, index) => {
        // Set the background according to the window size
        const style = {
          backgroundImage: `url(${width <= 850 ? slide.background_mobile : slide.background})`,
        };

        // Other slides with animations
        return (
          <div className="slide" key={index} style={style}>
            <div className="content">
              <div className="title">
                <h2>{slide.title}</h2>
              </div>

              <div className="description">
                <ReactMarkdown children={slide.description} />

                {slide.check_my_availability_button && (
                  <div className="buttons">
                    <Link to={`${config.base_url}coverage`}>
                      <Button className="gradient">
                        {formatMessage(messages.navigation_get_connected)}
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </div>

            {slide.images && (
              <div className="images">
                {slide.images.map((image, index) => (
                  <div key={index} className={index === 0 ? 'house' : 'house house-vx'}>
                    <img src={image} alt="" />
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
