import 'react-app-polyfill/ie11';

import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';

import useFetch from 'use-http';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

import { onError } from '@apollo/client/link/error';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';

import createRootReducer from './reducers/index';
import { loadState, saveState } from './utils/persist';

import mySaga from './sagas/index.saga.js';

import { fetchMyProfile } from './utils/api/';

import App from './App';
import LoadingSpinner from './components/LoadingSpinner';
import BrowserMessage from './components/BrowserMessage';
import ServiceMessage from './components/ServiceMessage';

import './assets/semantic/semantic.css';
import './index.css';

const AppWrapper = () => {
  // SET the local states
  const [store, setStore] = useState();
  const [config, setConfig] = useState();
  const [release, setRelease] = useState();
  const [loading, setLoading] = useState(true);
  const [objectGroups, setObjectGroups] = useState();

  // INITIALIZE fetch to perform requests
  const { get, response } = useFetch(config && config.api);
  const { get: getConfig, response: configResponse } = useFetch('./', { cachePolicy: 'no-cache' });
  const { get: getRelease, response: releaseResponse } = useFetch('./', {
    cachePolicy: 'no-cache',
  });

  // LOAD release and config files
  const getFiles = async () => {
    await getConfig('config.json');
    await getRelease('release.json');

    if (configResponse.ok && releaseResponse.ok) {
      if (!configResponse.data.api) {
        return (
          <div>
            <p>Missing API field in config file</p>
          </div>
        );
      }

      setConfig(configResponse.data);
      sessionStorage.setItem('config', JSON.stringify(configResponse.data));

      setRelease(releaseResponse.data);
      sessionStorage.setItem('build', JSON.stringify(releaseResponse.data));
    } else {
      return (
        <div>
          <p>Check syntax of release and/or config files</p>
        </div>
      );
    }
  };

  // CATCH GraphQL call errors
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.log(graphQLErrors);
    } else if (networkError) {
      console.log(networkError);
    }
  });

  // Refences to support multiple Apollo clients
  // https://medium.com/open-graphql/apollo-multiple-clients-with-react-b34b571210a5
  // https://loudnoises.io/blog/next-js-two-apollo-clients-two-graphql-data-sources-the-easy-way
  // The following needs to be used for a query/mutation on federation_api
  // context: { clientName: 'general' },
  const link = from([errorLink, new HttpLink({ uri: config?.federation_api })]);
  const eCommerceLink = from([errorLink, new HttpLink({ uri: config?.ecommerce_federation_api })]);

  const apolloClient = new ApolloClient({
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === 'general',
      link,
      eCommerceLink,
    ),
    cache: new InMemoryCache(),
  });

  // START Sentry if not on localhost
  const startSentry = async () => {
    await get('/api');
    if (response.ok) {
      if (process.env.NODE_ENV !== 'development') {
        Sentry.init({
          dsn: 'https://adda419bf05745fdadb7f0a94c2b9011@sentry.io/1424887',
          release: release.version,
          environment: response.data.cluster,
          integrations: [new BrowserTracing()],
          tracesSampleRate: 1.0,
        });
      }
    }
  };

  // GET the object groups of a specific network operator
  const getObjectGroups = async () => {
    await get(
      `/object_group?q=network_operator:${config.network_operator},visibility:true&fields=id,name,visibility`,
    );

    if (response.ok && response.data?.data) {
      // Retrieve object groups
      setObjectGroups(
        response.data.data.map((objectGroup) => ({
          ...objectGroup,
          // Subdomain URLs are computed as the lowercase version
          // of object group names without dashes and spaces
          subDomainURL: objectGroup.name.toLowerCase().replace(/-/g, '').replace(/ /g, ''), // FIXME: Do we really need this?
        })),
      );
    }
  };

  // GET release and config JSON files on mount
  useEffect(() => {
    getFiles();
  }, []);

  // Once the config is ready get object groups
  useEffect(() => {
    if (config) {
      getObjectGroups();
    }
  }, [config]);

  // Once both config and release are ready start Sentry
  useEffect(() => {
    if (config && release) {
      // Start Sentry to detect and trace errors
      startSentry();
    }
  }, [config, release]);

  // Once the config is ready, initialize the Redux store
  useEffect(() => {
    if (objectGroups) {
      initStore();
    }
  }, [objectGroups]);

  // GET my profile data and
  // INITIALIZE the Redux store
  const initStore = async () => {
    const reduxStore = await fetchMyProfile().then((initData) => {
      if (initData) {
        // Create the Saga middleware
        const sagaMiddleware = createSagaMiddleware();

        // Compose middlewares
        let enhancer = compose(
          applyMiddleware(
            // routerMiddleware(history),
            sagaMiddleware,
          ),
        );
        // Compose with the developer tools only in development mode
        enhancer = process.env.NODE_ENV === 'production' ? enhancer : composeWithDevTools(enhancer);

        // Load the state from the session storage
        const persistedState = loadState(config.api);

        // Define the Redux initial state
        const initialState = { init: initData, ...persistedState };

        // Create the Redux store
        const store = createStore(createRootReducer, initialState, enhancer);

        // Save "init" and "login" data in the session storage when they change
        let currentInit;
        let currentLogin;
        store.subscribe(() => {
          let previousInit = currentInit;
          let previousLogin = currentLogin;

          currentInit = store.getState().init;
          currentLogin = store.getState().login;

          if (previousInit !== currentInit || previousLogin !== currentLogin) {
            saveState(
              {
                init: store.getState().init,
                login: store.getState().login,
              },
              config.api,
            );
          }
        });

        // Start the saga middleware
        sagaMiddleware.run(mySaga);

        return store;
      }

      return <ServiceMessage />;
    });

    setStore(reduxStore);
    setLoading(false);
  };

  // SHOW a spinner until object groups are ready
  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <App config={config} release={release} objectGroups={objectGroups} />
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  );
};

const domNode = document.getElementById('root');
const root = createRoot(domNode);

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/ false || !!document.documentMode; // eslint-disable-line

// When an old browser is used, a message is shown and the application does not start
if (isIE) {
  root.render(<BrowserMessage />);
}

root.render(<AppWrapper />);
