import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

// Import common pages from library
import {
  FaqPage,
  NewsPage,
  ProfilePage,
  MarkdownPage,
  NewsPostPage,
  NotFoundPage,
  ContactUsPage,
  componentLoader,
  UnauthorizedPage,
  PasswordResetPage,
  CustomerServicesPage,
  RequestPasswordResetPage,
  AccountVerificationPage,
} from 'portal-components';

// Import specific contents
import faqContent from '../content/faqs.json';
import impressumContent from '../content/policies/impressum.json';
import cookiePolicyContent from '../content/policies/cookie-policy.json';
import dataProtectionContent from '../content/policies/data-protection.json';
import termsAndConditionsContent from '../content/policies/terms-and-conditions.json';
import cancellationPolicyContent from '../content/policies/cancellation-policy.json';

import HomePage from '../pages/HomePage';
import ResidentialPage from '../pages/ResidentialPage';
import BusinessPage from '../pages/BusinessPage';
import FullFibrePage from '../pages/FullFibrePage';
import LoadingSpinner from '../components/LoadingSpinner';

const AboutUsPage = lazy(() => componentLoader(() => import('../pages/AboutUsPage'), 3));
const CoveragePage = lazy(() => componentLoader(() => import('../pages/CoveragePage'), 3));
const OffersPage = lazy(() => componentLoader(() => import('../pages/OffersPage'), 3));
const ServiceCatalogPage = lazy(() =>
  componentLoader(() => import('../pages/ServiceCatalogPage'), 3),
);
const PersonalAreaPage = lazy(() => componentLoader(() => import('../pages/PersonalAreaPage'), 3));
const LoginPage = lazy(() => componentLoader(() => import('../pages/LoginPage'), 3));
const SignupPage = lazy(() => componentLoader(() => import('../pages/SignupPage'), 3));
const MyPaymentsPage = lazy(() => componentLoader(() => import('../pages/MyPaymentsPage'), 3));
const SpeedTestPage = lazy(() => componentLoader(() => import('../pages/SpeedTestPage'), 3));
const AppointmentPage = lazy(() => componentLoader(() => import('../pages/AppointmentPage'), 3));
const RescheduleAppointmentPage = lazy(() =>
  componentLoader(() => import('../pages/AppointmentPage/Reschedule'), 3),
);

const SteyrPage = lazy(() => componentLoader(() => import('../pages/SteyrPage'), 3));
const ZeillernPage = lazy(() => componentLoader(() => import('../pages/ZeillernPage'), 3));
const SalzgitterPage = lazy(() => componentLoader(() => import('../pages/SalzgitterPage'), 3));

const getRoutes = initialRouteProps => {
  const { init, ...routeProps } = initialRouteProps;

  const taxID = routeProps.config && routeProps.config.tax_id;
  const apiKey = init.api_key;
  const username = init.username;
  const autologin = init.role === 'autologin';

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route path={'/'} element={<HomePage {...routeProps} />} />

        {/* Content pages */}
        <Route path={'/full-fibre'} element={<FullFibrePage {...routeProps} />} />
        <Route path={'/residential'} element={<ResidentialPage {...routeProps} />} />
        <Route path={'/business'} element={<BusinessPage {...routeProps} />} />
        <Route path={'/offers'} element={<OffersPage {...routeProps} />} />
        <Route path={'/about-us'} element={<AboutUsPage {...routeProps} />} />
        <Route path={'/news'} element={<NewsPage {...routeProps} />} />
        <Route path={'/news-article'} element={<NewsPostPage {...routeProps} />} />
        <Route path={'/faq'} element={<FaqPage {...routeProps} contentData={faqContent} />} />

        {/* Order related pages */}
        <Route path={'/coverage'} element={<CoveragePage {...routeProps} />} />
        <Route path={'/appointment'} element={<AppointmentPage {...routeProps} />} />
        <Route
          path={'/reschedule-appointment'}
          element={<RescheduleAppointmentPage {...routeProps} />}
        />

        {/* Customer pages */}
        <Route path={'/login'} element={<LoginPage {...routeProps} />} />
        <Route path={'/signup'} element={<SignupPage {...routeProps} />} />
        <Route path={'/my-places'} element={<PersonalAreaPage {...routeProps} />} />
        <Route path={'/services'} element={<ServiceCatalogPage {...routeProps} />} />
        <Route path={'/payments'} element={<MyPaymentsPage {...routeProps} />} />
        <Route path={'/speed-test'} element={<SpeedTestPage {...routeProps} />} />
        <Route
          path={'/contact-us'}
          element={<ContactUsPage {...routeProps} showOfficeLocationSection={false} />}
        />
        <Route path={'/customer_password'} element={<PasswordResetPage {...routeProps} />} />
        <Route
          path={'/my-services'}
          element={<CustomerServicesPage {...routeProps} apiKey={apiKey} username={username} />}
        />
        <Route
          path={'/profile'}
          element={
            <ProfilePage {...routeProps} apiKey={apiKey} autologin={autologin} taxID={taxID} />
          }
        />
        <Route
          path={'/email_verification'}
          element={
            <AccountVerificationPage
              {...routeProps}
              processDefinitionKey="AccountVerificationProcessLilaAustria"
            />
          }
        />
        <Route
          path={'/request-password-reset'}
          element={<RequestPasswordResetPage {...routeProps} />}
        />

        {/* T&C and policy related pages */}
        <Route
          path={'/terms-and-conditions'}
          element={<MarkdownPage {...routeProps} contentData={termsAndConditionsContent} />}
        />
        <Route
          path={'/cookie-policy'}
          element={<MarkdownPage {...routeProps} contentData={cookiePolicyContent} />}
        />
        <Route
          path={'/impressum'}
          element={<MarkdownPage {...routeProps} contentData={impressumContent} />}
        />
        <Route
          path={'/data-protection'}
          element={<MarkdownPage {...routeProps} contentData={dataProtectionContent} />}
        />
        <Route
          path={'/cancellation-policy'}
          element={<MarkdownPage {...routeProps} contentData={cancellationPolicyContent} />}
        />

        {/* City specific pages */}
        <Route path={'/steyr'} element={<SteyrPage {...routeProps} />} />
        <Route path={'/zeillern'} element={<ZeillernPage {...routeProps} />} />
        <Route path={'/salzgitter'} element={<SalzgitterPage {...routeProps} />} />

        {/* Placeholder pages */}
        <Route path={'/unauthorized'} element={<UnauthorizedPage {...routeProps} />} />
        <Route path="*" element={<NotFoundPage {...routeProps} />} />
      </Routes>
    </Suspense>
  );
};

export default getRoutes;
