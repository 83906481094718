import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { useResponsive } from 'portal-components';
import { Button, Divider } from 'semantic-ui-react';

import messages from '../../messages';
import contentData from '../../content/pages/residential.json';

const ResidentialPage = (props) => {
  const { slides } = contentData;
  const {
    config,
    button,
    intl: { formatMessage },
  } = props;

  // Get window size
  const { isMobile } = useResponsive();

  return (
    <div className="residential top-page-spacing">
      {slides.map((slide, index) => {
        // Set the background according to the window size
        const style = {
          backgroundImage: `url(${isMobile ? slide.background_mobile : slide.background})`,
        };

        return (
          <div className="slide" key={`slide${index}`} style={style}>
            <div className="content">
              <div className="title">
                <h2>{slide.title}</h2>
              </div>
              <div className="description">
                <ReactMarkdown children={slide.description} />
                <Divider hidden />
                <div style={button} className="buttons">
                  {slide.check_my_availability_button && (
                    <Link to={`${config.base_url}coverage`}>
                      <Button className="gradient">
                        {formatMessage(messages.navigation_get_connected)}
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default injectIntl(ResidentialPage);
