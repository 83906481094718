import init from './init.reducer';
import login from './login.reducer';
import order from './order.reducer';
import modal from './modal.reducer';
import spinner from './spinner.reducer';
import service from './service.reducer';
import object from './object.reducer';
import lang from './lang.reducer';
import userlang from './userLang.reducer';
import customerType from './customerType.reducer';

const rootReducer = (state = {}, action) => {
  return {
    init: init(state.init, action),
    login: login(state.login, action),
    order: order(state.order, action),
    modal: modal(state.modal, action),
    spinner: spinner(state.spinner, action),
    service: service(state.service, action),
    object: object(state.object, action),
    lang: lang(state.lang, action),
    userlang: userlang(state.userlang, action),
    customerType: customerType(state.customerType, action),
  };
};

export default function createRootReducer(state, action) {
  let newState = state;

  // Reset the state (except for some data such as lang and routing) when users logout
  if (action.type === 'REQUEST_LOGOUT') {
    const { lang, userlang, order } = state;
    newState = { lang, userlang, order };
  }

  return rootReducer(newState, action);
}
