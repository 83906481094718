import { put, takeLatest, call } from 'redux-saga/effects';

import { REQUEST_INIT_DATA, receiveInitData } from './../actions/init.actions';
import { REQUEST_LOGIN_DATA, REQUEST_LOGOUT, receiveLoginData } from './../actions/login.actions';
import {
  REQUEST_SHOW_MODAL,
  REQUEST_HIDE_MODAL,
  receiveShowModal,
} from './../actions/modal.actions';
import {
  REQUEST_SHOW_SPINNER,
  receiveShowSpinner,
  requestHideSpinner,
} from './../actions/spinner.actions';
import { REQUEST_ORDER_DATA, REQUEST_ORDER_GET, receiveOrderGet } from './../actions/order.actions';
import { REQUEST_SERVICE, receiveService, resetService } from './../actions/service.actions';
import { REQUEST_OBJECT, receiveObject } from './../actions/object.actions';

import {
  fetchMyProfile,
  fetchLoginToken,
  fetchOrder,
  fetchObject,
  fetchGetOrder,
} from './../utils/api/';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getInitData(action) {
  try {
    // do first init call
    const data = yield call(fetchMyProfile);
    yield put(receiveInitData(data));
  } catch (e) {
    alert(e.message);
  }
}

function* getLoginData(action) {
  try {
    // do Login call
    const api_key = yield call(fetchLoginToken, action.data);
    const profileData = yield call(fetchMyProfile, sessionStorage.getItem('api_key'));

    if (api_key) {
      if (api_key.status && api_key.status === 401) {
        yield put(
          receiveLoginData({
            errorStatus: api_key.status,
            errorMessage: api_key.statusText,
          }),
        );
        yield put(receiveShowModal(api_key.status, 'error'));
        yield put(requestHideSpinner());
      } else if (api_key.status && api_key.status === 500) {
        yield put(
          receiveLoginData({
            errorStatus: api_key.status,
            errorMessage: api_key.status,
          }),
        );
        yield put(receiveShowModal(api_key.status, 'error'));
        yield put(requestHideSpinner());
      } else {
        yield put(receiveLoginData(api_key));
        yield put(receiveInitData(profileData)); // Update State.Init with profile data

        // let pageRedirect = '';

        // if (profileData.role === "installer") {
        //   pageRedirect = "installer";
        // } else if (profileData.role !== "customer") {
        //   pageRedirect = "unauthorized";
        // } else {
        //   pageRedirect = "my-places";
        // }

        // pageRedirect = action.data.pageRedirect || pageRedirect;
        // browserHistory.push(`${config.base_url}${pageRedirect}`);

        yield put(requestHideSpinner());
      }
    } else {
      throw api_key;
    }
  } catch (error) {
    console.log(error);
  }
}

function* getOrder(action) {
  try {
    const orderData = yield call(fetchGetOrder, sessionStorage.getItem('api_key'));
    yield put(receiveOrderGet(orderData)); // Update State.Order
  } catch (e) {
    alert(e.message);
  }
}

function* postOrder(action) {
  try {
    const orderData = yield call(fetchOrder, sessionStorage.getItem('api_key'), action.data);
    // yield put(receiveOrderData(orderData)); // Update State.Order

    console.log('from POST to Order api ', orderData);

    if (orderData.error) {
      yield put(receiveShowModal(orderData.message, 'error'));
      yield put(requestHideSpinner());
    } else {
      yield put(receiveShowModal(orderData, 'confirm'));
      yield put(requestHideSpinner());
    }
  } catch (e) {
    alert(e.message);
  }
}

function* showModal(action) {
  try {
    if (action.modalType === 'preorder') {
      yield put(receiveShowModal(action.data, action.modalType));
      yield put(requestHideSpinner());
    }
    if (action.modalType === 'error') {
      const orderData = yield call(fetchOrder, sessionStorage.getItem('api_key'), action.data);
      yield put(receiveShowModal(orderData, action.modalType));
      yield put(requestHideSpinner());
    }
  } catch (e) {
    alert(e.message);
  }
}

function* hideModal(action) {
  yield put(resetService());
}

function* showSpinner(action) {
  yield put(receiveShowSpinner());
}

function* storeService(action) {
  yield put(receiveService(action.service));
}

function* storeObject(action) {
  try {
    const object = yield call(fetchObject, sessionStorage.getItem('api_key'), action.data);
    yield put(receiveObject(object));
    yield put(requestHideSpinner());
  } catch (e) {
    alert(e.message);
  }
}

function* getLogout(action) {
  try {
    const data = yield call(fetchMyProfile); // fetchMyProfile == fetchMyProfile but without api_key in query string
    // const object = yield call(fetchObject, sessionStorage.getItem("api_key"));
    yield put(receiveInitData(data));
    // yield put(receiveObject(object));
    yield put(requestHideSpinner());
  } catch (e) {
    alert(e.message);
  }
}
/*
  Alternatively you may use takeLatest.
  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
export default function* mySaga() {
  yield takeLatest(REQUEST_INIT_DATA, getInitData); // INIT - APP START HERE
  yield takeLatest(REQUEST_LOGIN_DATA, getLoginData); // LOGIN+ API_KEY + COMPLETE PROFILE DATA
  yield takeLatest(REQUEST_ORDER_DATA, postOrder); // APP SERVICES ORDERS
  yield takeLatest(REQUEST_ORDER_GET, getOrder); // APP SERVICES ORDERS
  yield takeLatest(REQUEST_SHOW_MODAL, showModal); // SHOW MODAL
  yield takeLatest(REQUEST_HIDE_MODAL, hideModal); // HIDE MODAL
  yield takeLatest(REQUEST_SHOW_SPINNER, showSpinner); // SHOW SPINNER
  yield takeLatest(REQUEST_SERVICE, storeService); // STORE SERVICE
  yield takeLatest(REQUEST_OBJECT, storeObject); // STORE OBJECT
  yield takeLatest(REQUEST_LOGOUT, getLogout); // LOGOUT
}
